<template>
  <div>
    <div ref="myscript" />
    <div id="app" ref="appContent">
      <div
        class="spinner-container"
        :class="[empty(event.backgroundImage) && 'secondary']"
        :style="
          !empty(event.backgroundImage) && {
            background: `url(${event.backgroundImage}) no-repeat 50% 50%`,
            backgroundSize: 'cover',
          }
        "
      >
        <div v-if="loading" class="pulse-loader">
          <pulse-loader :loading="loading" />
        </div>
        <div v-else-if="invalidEvent" class="spinner-container">
          <Alert message="Invalid Event ID. Please try again." />
        </div>
        <div v-else-if="isResetPassword" class="verify-container">
          <v-card :loading="loadingReset" :disabled="loadingReset" class="rounded-xl" elevation="10">
            <template slot="progress">
              <v-progress-linear color="secondary" height="10" indeterminate />
            </template>
            <div class="pa-6">
              <v-card-title class="logo">
                <template v-if="empty(event.charityLogo)">
                  <XLogo class="event-dummy-logo" />
                  <span class="event-dummy-text">
                    <p>CHARITY</p>
                    <p>LOGO</p>
                  </span>
                </template>
                <img v-else :src="event.charityLogo" alt="charity logo" />
              </v-card-title>
              <h2 class="text-center py-4">Reset Password</h2>
              <v-form ref="form" v-model="registrationFormValid" lazy-validation class="mb-7">
                <v-text-field :color="colorFromEvent" v-model="password" :rules="passwordRules" label="New password" outlined required type="password" />

                <v-alert dense outlined text :type="isError ? 'error' : 'success'" v-if="!empty(errorReset)">
                  {{ errorReset }}
                </v-alert>

                <v-btn :disabled="!registrationFormValid || loadingReset" color="primary" block class="mt-4" @click="submitResetPassword"> Submit </v-btn>

                <v-btn v-if="!empty(errorReset)" text color="primary" block class="mt-4" @click="requestResetPassword"> Re-send </v-btn>
              </v-form>
            </div>
          </v-card>
        </div>
        <div v-if="!empty(error)" class="d-flex flex-column align-center">
          <v-alert outlined type="warning">
            {{ error }}
          </v-alert>

          <v-btn color="primary" elevation="4" rounded @click="goToEventLobby()"> Go to event lobby </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader';
import Alert from '@/components/alert';
import XLogo from '@/assets/images/XLogo';
import { confirmSignUp, reSendConfirmSignUp, verifyResetPassword, requestResetPassword } from '@/aws/authentication';
import { empty, wait } from '@/utils';
import { mapState } from 'vuex';
export default {
  components: {
    PulseLoader,
    Alert,
    XLogo,
  },
  data: () => ({
    loadingReset: false,
    invalidEvent: false,
    loading: true,
    isError: true,
    error: '',
    errorReset: '',
    passwordRules: [v => !!v || 'Password is required'],
    registrationFormValid: false,
    password: '',
  }),
  computed: {
    isResetPassword() {
      return this.$route.name === 'ForgotPassword';
    },
    ...mapState({
      event: 'event',
      eventColor: 'eventColor',
      verifyToken: 'verifyToken',
    }),
    colorFromEvent() {
      const { primaryColour, secondaryColor } = this.eventColor;
      return primaryColour == '#ffffff' ? secondaryColor : primaryColour;
    },
  },
  watch: {
    $route: {
      async handler(route) {
        const { code, email } = route.query;
        console.log(this.verifyToken[email]);
        if (empty(this.verifyToken[email])) {
          this.loading = false;
          this.error = 'Please verify same browser/device. Please try again';
        } else {
          if (empty(code) || empty(email)) {
            this.loading = false;
            switch (route.name) {
              case 'ActiveAccount':
                this.error = 'Can not verify your account. Please contact with support to solve your problem.';
                break;
              case 'ForgotPassword':
                this.error = 'Can not reset password. Please contact with support to solve your problem.';
                break;
            }
          } else {
            const { code, email } = this.$route.query;
            try {
              this.loading = true;
              if (route.name === 'ActiveAccount') {
                const resp = await confirmSignUp(email, code);
                console.log(resp);
                this.goToEventLobby(true);
              }
            } catch (error) {
              console.log({ error });
              switch (error.code) {
                case 'NotAuthorizedException':
                  return this.goToEventLobby(true);
                case 'ExpiredCodeException':
                  this.error = 'Your confirm was expired. We will send a email again, please check your email to complete signing up process';
                  // eslint-disable-next-line no-case-declarations
                  const resp = await reSendConfirmSignUp(email, this.event.id);
                  console.log(resp);
                  await wait(1000);
                  this.error = resp.message;
                  return;
                default:
                  this.error = error.message;
              }
            } finally {
              this.loading = false;
            }
          }
        }
      },
      immediate: true,
    },
  },
  async created() {
    this.invalidEvent = !this.event.id;
  },
  methods: {
    empty,
    async requestResetPassword() {
      try {
        const { email } = this.$route.query;
        this.loadingReset = true;
        await requestResetPassword(email, this.event.id);

        this.isError = false;
        this.errorReset = 'Please your mail to reset password.';
      } catch (error) {
        console.log({ error });
        this.isError = true;
        this.errorReset = error.message;
      } finally {
        this.loadingReset = false;
      }
    },
    async submitResetPassword() {
      try {
        const { code, email } = this.$route.query;
        this.loadingReset = true;
        await verifyResetPassword(email, this.password, code);
        await wait(1000);
        this.isError = false;
        this.errorReset = 'Change password successfully! Redirecting to event lobby';
        this.goToEventLobby(true);
      } catch (error) {
        console.log({ error });
        this.errorReset = error.message;
      } finally {
        this.loadingReset = false;
      }
    },
    goToEventLobby(isLogin = false) {
      let query = {};
      if (isLogin) {
        query = {
          action: 'SIGN_IN',
        };
      }
      this.$router.push({
        name: 'Home',
        params: {
          event_id: this.$route.params.event_id,
        },
        query,
      });
    },
  },
};
</script>
<style lang="scss">
.verify-container {
  width: 90%;
  margin: auto;
  max-width: 400px;
  .logo {
    max-width: 70%;
    margin: auto;
  }
}
</style>
